import React from "react";
import { FormattedDate } from "react-intl";

const WeekDays = () => {
	return (
		<div className="week-days">
			<div className="week-days__item">
				<FormattedDate value={1610364898000} weekday="short" />
			</div>
			<div className="week-days__item">
				<FormattedDate value={1609846498000} weekday="short" />
			</div>
			<div className="week-days__item">
				<FormattedDate value={1609932898000} weekday="short" />
			</div>
			<div className="week-days__item">
				<FormattedDate value={1610019298000} weekday="short" />
			</div>
			<div className="week-days__item">
				<FormattedDate value={1610105698000} weekday="short" />
			</div>
			<div className="week-days__item">
				<FormattedDate value={1610192098000} weekday="short" />
			</div>
			<div className="week-days__item">
				<FormattedDate value={1610278498000} weekday="short" />
			</div>
		</div>
	);
};

export default React.memo(WeekDays);
