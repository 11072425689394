import React, { useCallback } from "react";
import PropTypes from "prop-types";
import TravellersRoomInput from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomInput";
import "./TravellersRoomFieldset.scss";
import { FormattedMessage } from "react-intl";

const TravellersRoomFieldset = ({ index, showRemoveButton, onRemoveClick }) => {
	const handleRemoveClick = useCallback(() => {
		onRemoveClick(index);
	}, [index]);

	return (
		<div className="travellers-room-fieldset">
			<div className="travellers-room-fieldset__header">
				<div className="travellers-room-fieldset__title">
					<FormattedMessage
						id="sdp.search.occupancies.room.label"
						values={{ number: index + 1 }}
					/>
				</div>
				{showRemoveButton && (
					<div className="travellers-room-fieldset__remove">
						<div
							type="button"
							onClick={handleRemoveClick}
							data-cy={`remove-room-button-${index + 1}`}
						>
							- <FormattedMessage id="sdp.search.occupancies.room.remove.label" />
						</div>
					</div>
				)}
			</div>
			<TravellersRoomInput name={`occupancies.${index}`} index={index} />
		</div>
	);
};

TravellersRoomFieldset.propTypes = {
	index: PropTypes.number,
	showRemoveButton: PropTypes.bool,
	onRemoveClick: PropTypes.func,
};

export default React.memo(TravellersRoomFieldset);
