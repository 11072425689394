import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import {
	defaultValues,
	validateDepartureCity,
	validateDepartureDate,
	validateDestination,
	validateSearch,
} from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";
import { Form, Formik } from "formik";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage, useIntl } from "react-intl";
import range from "lodash/range";
import get from "lodash/get";
import Button from "app/pages/.shared/form/Button";
import AdvancedSelectFormik from "app/pages/.shared/form/AdvancedSelectFormik/AdvancedSelectFormik";
import DateCalendarInput from "app/pages/SmartDP/Search/DateCalendarInput/DateCalendarInput";
import TravellersRoomButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomButton";
import "./SmartDPSearchForm.scss";
import IconLocation from "app/pages/.shared/IconLocation";
import IconCalendar from "app/pages/.shared/IconCalendar";
import IconDuration from "app/pages/.shared/IconDuration";
import IconCabin from "app/pages/.shared/IconCabin";
import IconOccupancies from "app/pages/.shared/IconOccupancies";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import GlobalErrorMessagesFormik from "app/pages/.shared/form/GlobalErrorMessagesFormik";
import { ALERT_TYPE } from "app/constants";
import OccupanciesDisplayLabel from "app/pages/.shared/OccupanciesDisplayLabel/OccupanciesDisplayLabel";
import IconLocationZone from "app/pages/.shared/IconLocationZone";
import { isServerSide } from "app/utils/utils";
import IconDeparting from "app/pages/.shared/IconDeparting";
import IconBack from "app/pages/.shared/static/icons/IconBack";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import IconMagnifyingGlass from "app/pages/.shared/IconMagnifyingGlass";

const DURATIONS_VALUES = range(2, 22).map(duration => {
	return {
		code: duration,
		value: duration,
	};
});

const SmartDPSearchForm = ({
	onSuccess,
	departureCities,
	destinations,
	cabins,
	departureDateMin,
	departureDateMax,
	initialValues,
	hideSidePanel,
}) => {
	const intl = useIntl();
	const { pathname } = useLocation();

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const handleSubmit = useCallback(
		(values, actions) => {
			actions.setSubmitting(false);
			onSuccess(values, pathname);
		},
		[departureCities, destinations, cabins, pathname]
	);

	// fix pour ne pas avoir les labels sur 2 lignes au premier rendu
	const mergeInitialValues = isServerSide
		? {
				occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }],
		  }
		: {
				...defaultValues,
				...initialValues,
		  };

	const isDestinationCountryOrRegion = type => type === "Country" || type === "Region";

	const getIconDestination = type => {
		if (isMobile) {
			if (isDestinationCountryOrRegion(type)) {
				return <IconLocationZone />;
			}
			return <IconLocation />;
		}
		return undefined;
	};

	const isListingOrQuote = () => pathname === "/sdp/listing" || pathname === "/sdp/booking/quote";

	const errorClassName = classNames({
		"sdp-search-form--error": isListingOrQuote(),
	});

	const isNoCabins = () => isServerSide || cabins.length > 0;

	return (
		<div className="sdp-search-form">
			{isListingOrQuote() && isMobile && (
				<header className="sdp-search-form__header">
					<div className="sdp-search-form__back" onClick={hideSidePanel}>
						<IconBack />
					</div>
					<div className="sdp-search-form__title">
						<FormattedMessage id="sdp.search.form.button.label" />
					</div>
					<div className="sdp-search-form__reset" />
				</header>
			)}
			<Formik
				enableReinitialize
				initialValues={mergeInitialValues}
				validate={validateSearch}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={handleSubmit}
			>
				{({ values, errors, isValid, isSubmitting, setFieldError, setFieldValue }) => {
					const handleTravellersConfirmation = values => {
						setFieldValue("occupancies", values);
					};
					return (
						<>
							<Form
								className="sdp-search-form__form"
								onChange={event => {
									// Permet de supprimer l'état en erreur du champ en erreur lorsque le user commence à le  modifier
									// Actuellement pas possible de le faire facilement avec formik
									// https://github.com/formium/formik/issues/2727
									if (!isEmpty(errors)) {
										setFieldError(event.target.name);
									}
								}}
							>
								<AdvancedSelectFormik
									id="departureCity"
									name="departureCity"
									openMenuOnFocus={false}
									openMenuOnClick={false}
									className="sdp-search-form__field"
									data-cy="departure-city-input"
									validate={validateDepartureCity}
									label={
										<FormattedMessage id="sdp.search.departure.city.input.label" />
									}
									getOptionLabel={({ label = "", suffix = "" }) =>
										`${label}${suffix ? `, ${suffix}` : ""}`
									}
									formatOptionLabel={(
										{ label = "", suffix = "" },
										{ inputValue }
									) => {
										const higlightedLabel = label.replace(
											inputValue,
											`<strong>${inputValue}</strong>`
										);
										return (
											<div className="sdp-search-form__suggestion">
												<div className="sdp-search-form__suggestion-picto">
													<IconDeparting />
												</div>
												<span
													className="sdp-search-form__suggestion-label"
													dangerouslySetInnerHTML={{
														__html: `${higlightedLabel}${
															suffix ? `, ${suffix}` : ""
														}`,
													}}
												/>
											</div>
										);
									}}
									icon={isMobile ? <IconDeparting /> : undefined}
									getOptionValue={({ code }) => code}
									options={departureCities}
									value={departureCities.find(({ code }) => {
										return code === get(values, "departureCity.code");
									})}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
								/>

								<AdvancedSelectFormik
									id="destinationResort"
									name="destinationResort"
									openMenuOnFocus={false}
									openMenuOnClick={false}
									validate={validateDestination}
									className="sdp-search-form__field"
									data-cy="destination-resort-input"
									label={
										<FormattedMessage id="sdp.search.destination.input.label" />
									}
									getOptionLabel={({ label = "", suffix = "" }) =>
										`${label}${suffix ? `, ${suffix}` : ""}`
									}
									formatOptionLabel={(
										{ label = "", suffix = "", type = "" },
										{ inputValue }
									) => {
										const higlightedLabel = label.replace(
											inputValue,
											`<strong>${inputValue}</strong>`
										);

										const higlightedSuffix =
											suffix &&
											suffix.replace(
												inputValue,
												`<strong>${inputValue}</strong>`
											);

										return (
											<div className="sdp-search-form__suggestion">
												<div className="sdp-search-form__suggestion-picto">
													{isDestinationCountryOrRegion(type) ? (
														<IconLocationZone />
													) : (
														<IconLocation />
													)}
												</div>
												<span
													className="sdp-search-form__suggestion-label"
													dangerouslySetInnerHTML={{
														__html: `${higlightedLabel}${
															higlightedSuffix
																? `, ${higlightedSuffix}`
																: ""
														}`,
													}}
												/>
											</div>
										);
									}}
									icon={getIconDestination(get(values, "destinationResort.type"))}
									getOptionValue={({ code }) => code}
									options={destinations}
									value={destinations.find(({ code }) => {
										return (
											code === Number(get(values, "destinationResort.code"))
										);
									})}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
								/>

								<DateCalendarInput
									id="departureDate"
									name="departureDate"
									data-cy="departure-date-input"
									validate={validateDepartureDate}
									icon={isMobile ? <IconCalendar /> : undefined}
									className="sdp-search-form__field sdp-search-form__field--small"
									label={
										<FormattedMessage id="sdp.search.departure.date.input.label" />
									}
									departureDateMin={
										get(values, "destinationResort.departureDateStart") ||
										departureDateMin
									}
									departureDateMax={
										get(values, "destinationResort.departureDateEnd") ||
										departureDateMax
									}
								/>

								<AdvancedSelectFormik
									id="duration"
									name="duration"
									className="sdp-search-form__field sdp-search-form__field--small"
									data-cy="duration-input"
									label={
										<FormattedMessage id="sdp.search.duration.input.label" />
									}
									isSearchable={false}
									icon={isMobile ? <IconDuration /> : undefined}
									getOptionLabel={({ value }) =>
										intl.formatMessage(
											{ id: "sdp.search.duration.value" },
											{ duration: value }
										)
									}
									getOptionValue={({ code }) => code}
									options={DURATIONS_VALUES}
									value={DURATIONS_VALUES.find(({ code }) => {
										return code === get(values, "duration.code");
									})}
								/>

								<TravellersRoomButton
									icon={isMobile ? <IconOccupancies /> : undefined}
									className={classNames("sdp-search-form__field", {
										"sdp-search-form__field--small": isNoCabins(),
									})}
									valueToDisplay={
										<OccupanciesDisplayLabel occupancies={values.occupancies} />
									}
									onConfirmation={handleTravellersConfirmation}
									initialValues={values.occupancies}
									label={
										<FormattedMessage id="sdp.search.travellers.input.label" />
									}
								/>

								{isNoCabins() && (
									<AdvancedSelectFormik
										id="cabin"
										name="cabin"
										className="sdp-search-form__field sdp-search-form__field--small"
										data-cy="cabin-input"
										isSearchable={false}
										icon={isMobile ? <IconCabin /> : undefined}
										label={
											<FormattedMessage id="sdp.search.cabin.input.label" />
										}
										getOptionLabel={({ label = "", suffix = "" }) =>
											`${label}${suffix ? `, ${suffix}` : ""}`
										}
										getOptionValue={({ code }) => code}
										options={cabins}
										value={cabins.find(({ code }) => {
											return code === get(values, "cabin.code");
										})}
									/>
								)}

								<div className="sdp-search-form__footer">
									{isListingOrQuote() && !isMobile ? (
										<Button
											design="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-cy="sdp-search-form-button"
										>
											<IconMagnifyingGlass />
										</Button>
									) : (
										<Button
											design="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-cy="sdp-search-form-button"
										>
											<FormattedMessage id="sdp.search.cta.label" />
										</Button>
									)}
								</div>
							</Form>
							{!isValid && (
								<div className={errorClassName}>
									<GlobalErrorMessagesFormik
										disableScroll={true}
										isValid={isValid}
										isSubmitting={isSubmitting}
										message={
											<FormattedMessage id="sdp.search.form.error.message" />
										}
										alertType={ALERT_TYPE.ERROR}
									/>
								</div>
							)}
						</>
					);
				}}
			</Formik>
		</div>
	);
};

SmartDPSearchForm.propTypes = {
	onSuccess: PropTypes.func,
	departureCities: PropTypes.array,
	destinations: PropTypes.array,
	cabins: PropTypes.array,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
	initialValues: PropTypes.object,
	hideSidePanel: PropTypes.func,
};

SmartDPSearchForm.defaultProps = {
	initialValues: {},
	departureCities: [],
	destinations: [],
	cabins: [],
	hideSidePanel: () => {},
};

export default React.memo(SmartDPSearchForm);
