import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import "./SmartDPHighlighted.scss";
import { resolutionPropType } from "app/utils/propTypes";

const IMG_SIZES = {
	small: { width: 320 },
	medium: { width: 320 },
	large: { width: 372 },
	xlarge: { width: 372 },
};

const SmartDPHighlighted = ({ picture, reasonToBook, resolution }) => {
	const highlightedReasonPicture = addOptionsToCloundinaryURL(picture, [
		`w_${get(IMG_SIZES[resolution], "width")},c_fill,g_center`,
	]);

	return (
		<div className="sdp-reasons-dynamic">
			<div className="sdp-reasons-dynamic__container">
				<div className="sdp-reasons-dynamic__icon-container">
					<img src={reasonToBook.image} />
				</div>
				<span className="sdp-reasons-dynamic__title">{reasonToBook.title}</span>
				<span
					className="sdp-reasons-dynamic__texte"
					dangerouslySetInnerHTML={{
						__html:
							reasonToBook.highlightedDescription &&
							reasonToBook.highlightedDescription[0],
					}}
				/>
			</div>
			<div className="sdp-reasons-dynamic__image">
				<img src={highlightedReasonPicture} />
			</div>
		</div>
	);
};

SmartDPHighlighted.propTypes = {
	picture: PropTypes.string,
	reasonToBook: PropTypes.object,
	resolution: resolutionPropType,
};

SmartDPHighlighted.defaultProps = {
	reasonToBook: {},
};

export default React.memo(SmartDPHighlighted);
