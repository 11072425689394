import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { calculateAgeFromBirthday } from "app/utils/utils";
import { isAfter } from "date-fns";

export const defaultValues = {
	departureCity: "",
	destinationResort: "",
	departureDate: "",
	duration: {
		code: 7,
		value: 7,
	},
	occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }],
	cabin: "",
};

export const validateDepartureCity = values => {
	let error;

	if (!values) {
		error = {
			id: "sdp.search.departure.city.input.error",
		};
	}

	return error;
};

export const validateDestination = values => {
	let error;

	if (!values) {
		error = {
			id: "sdp.search.destination.input.error",
		};
	}

	return error;
};

export const validateDepartureDate = values => {
	let error;

	if (!values) {
		error = {
			id: "sdp.search.departure.date.input.error",
		};
	}

	return error;
};

export const validateSearch = (values = {}) => {
	const errors = {};
	const today = new Date();
	const occupancies = values.occupancies || [];

	occupancies.forEach((occupancy, index) => {
		const childrenBirthdates = occupancy.childrenBirthdates || [];
		const adults = occupancy.adults;

		childrenBirthdates.forEach((childrenBirthdate, birdthdateIndex) => {
			if (
				!childrenBirthdate ||
				isUndefined(childrenBirthdate.day) ||
				isUndefined(childrenBirthdate.month) ||
				isUndefined(childrenBirthdate.year)
			) {
				if (!errors.occupancies) {
					errors.occupancies = [];
				}

				if (!errors.occupancies[index]) {
					errors.occupancies[index] = {};
				}

				if (!errors.occupancies[index].childrenBirthdates) {
					errors.occupancies[index] = { childrenBirthdates: [] };
				}

				errors.occupancies[index].childrenBirthdates[birdthdateIndex] = {
					id: "error.form.generic",
				};
			}
			if (
				childrenBirthdate &&
				!isEmpty(childrenBirthdate) &&
				childrenBirthdate.day >= 0 &&
				childrenBirthdate.month >= 0 &&
				childrenBirthdate.year >= 0 &&
				isAfter(
					new Date(
						childrenBirthdate.year,
						childrenBirthdate.month,
						childrenBirthdate.day,
						0,
						0,
						0
					),
					new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				)
			) {
				if (!errors.occupancies) {
					errors.occupancies = [];
				}

				if (!errors.occupancies[index]) {
					errors.occupancies[index] = {};
				}

				if (!errors.occupancies[index].childrenBirthdates) {
					errors.occupancies[index] = { childrenBirthdates: [] };
				}
				errors.occupancies[index].childrenBirthdates[birdthdateIndex] = {
					id: "sdp.search.children.ages.past.today.error.label",
				};
			}
		});

		const babies = childrenBirthdates.filter(childrenBirthdate => {
			let result = false;

			if (
				childrenBirthdate &&
				!isEmpty(childrenBirthdate) &&
				childrenBirthdate.day >= 0 &&
				childrenBirthdate.month >= 0 &&
				childrenBirthdate.year >= 0
			) {
				const birdthdate = new Date(
					childrenBirthdate.year,
					childrenBirthdate.month,
					childrenBirthdate.day,
					0,
					0,
					0
				);

				const age = calculateAgeFromBirthday(birdthdate);

				result = age <= 2;
			}

			return result;
		});

		if (babies.length > adults) {
			if (!errors.occupancies) {
				errors.occupancies = [];
			}
			errors.occupancies[index] = {};
			errors.occupancies[index] = { childrenBirthdates: [] };
			errors.occupancies[index].childrenBirthdates = childrenBirthdates.map(() => {
				return {
					id: "sdp.search.children.ages.baby.more.adult.error.label",
				};
			});
		}
	});

	return errors;
};
