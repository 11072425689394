import React from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import isEmpty from "lodash/isEmpty";
import TravellersRoomFieldset from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomFieldset";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import { FieldArray, Form, Formik } from "formik";
import { validateSearch } from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";

const TravellersRoomForm = ({ handleValidation, onOutsideClick, initialValues }) => {
	TravellersRoomForm.handleClickOutside = () => onOutsideClick();

	const defaultValues = [{ adults: 2, children: 0 }];

	return (
		<Formik
			enableReinitialize
			initialValues={{
				occupancies: isEmpty(initialValues) ? defaultValues : initialValues,
			}}
			validate={validateSearch}
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={handleValidation}
		>
			{({ values, errors, setFieldError }) => {
				return (
					<Form
						onChange={event => {
							// Permet de supprimer l'état en erreur du champ en erreur lorsque le user commence à le  modifier
							// Actuellement pas possible de le faire facilement avec formik
							// https://github.com/formium/formik/issues/2727
							if (!isEmpty(errors)) {
								setFieldError(event.target.name);
							}
						}}
					>
						<FieldArray
							name="occupancies"
							render={arrayHelpers => {
								return (
									<div className="travellers-room-button__popover-body">
										<div className="travellers-room-button__body">
											{values.occupancies.map((friend, index) => (
												<div
													key={index}
													className="travellers-room-button__fieldset"
												>
													<TravellersRoomFieldset
														index={index}
														showRemoveButton={index >= 1}
														onRemoveClick={() =>
															arrayHelpers.remove(index)
														}
													/>
												</div>
											))}
										</div>
										<div className="travellers-room-button__footer">
											<div
												className="travellers-room-button__add-button"
												data-cy="add-room-button"
												onClick={() =>
													arrayHelpers.push({
														adults: 2,
														children: 0,
													})
												}
											>
												+{" "}
												<FormattedMessage id="sdp.search.occupancies.room.add.label" />
											</div>
											<Button
												submit
												design="primary"
												data-cy="validate-button"
											>
												<FormattedMessage id="sdp.search.occupancies.room.validate.button.label" />
											</Button>
										</div>
									</div>
								);
							}}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

TravellersRoomForm.propTypes = {
	handleValidation: PropTypes.func,
	onOutsideClick: PropTypes.func,
	initialValues: PropTypes.array,
};

TravellersRoomForm.defaultProps = {
	initialValues: [],
};

const clickOutsideConfig = {
	handleClickOutside: () => TravellersRoomForm.handleClickOutside,
};

export default onClickOutside(TravellersRoomForm, clickOutsideConfig);
