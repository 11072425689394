import React, { useCallback, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { messagePropType } from "app/utils/propTypes";
import Popover from "react-popover";
import "./TravellersRoomButton.scss";
import classNames from "classnames";
import { scrollToElement } from "app/utils/scroller";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import TravellersRoomForm from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomForm";

const TravellersRoomButton = props => {
	const { label, className, icon, initialValues, valueToDisplay, onConfirmation } = props;
	const [isDropdownOpen, toggleDropdownOpen] = useState(false);
	const ref = useRef();
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const handleValidation = useCallback(
		values => {
			handleDropdownClose();
			if (ref && ref.current) {
				scrollToElement(ref.current, -300, false);
			}
			onConfirmation(values.occupancies);
		},
		[ref.current]
	);

	const handleDropdownClose = useCallback(() => {
		toggleDropdownOpen(false);
	}, []);

	const handleDropdownOpen = useCallback(() => {
		toggleDropdownOpen(true);
	}, []);

	const inputClassName = classNames(
		"travellers-room-button",
		"travellers-room-button--touched",
		className,
		{
			"travellers-room-button--with-icon": icon,
		}
	);

	return (
		<div className={inputClassName} ref={ref}>
			<label className="travellers-room-button__label">{label}</label>
			<Popover
				className="travellers-room-button__popover"
				enterExitTransitionDurationMs={300}
				tipSize={isMobile ? 15 : 9}
				body={
					<TravellersRoomForm
						initialValues={initialValues}
						handleValidation={handleValidation}
						onOutsideClick={isMobile ? () => {} : handleDropdownClose}
					/>
				}
				isOpen={isDropdownOpen}
				place="below"
			>
				<div
					onClick={handleDropdownOpen}
					className="travellers-room-button__input"
					data-cy="travellers-room-button"
				>
					{icon && <div className="travellers-room-button__icon">{icon}</div>}
					{valueToDisplay}
				</div>
			</Popover>
		</div>
	);
};

TravellersRoomButton.propTypes = {
	label: messagePropType,
	className: PropTypes.string,
	icon: PropTypes.element,
	valueToDisplay: PropTypes.element,
	onConfirmation: PropTypes.func,
	initialValues: PropTypes.array,
};

TravellersRoomButton.defaultProps = {
	initialValues: [],
	onConfirmation: () => {},
};

export default React.memo(TravellersRoomButton);
