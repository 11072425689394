import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import SmartDPSearchForm from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchForm";
import { useHistory } from "react-router-dom";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import AppGlobalsContext from "app/AppGlobalsContext";
import "./SmartDPSearch.scss";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import SmartDPReasons from "app/pages/SmartDP/Search/SmartDPReasons/SmartDPReasons";
import { sendTagOnSDPSearchPage } from "app/utils/analytics";
import SmartDPBanner from "app/pages/SmartDP/Search/SmartDPBanner/SmartDPBanner";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";
import { SDP_HERO_TEXT_ALIGN, SDP_SEARCH_MARKETING_BLOCKS } from "app/constants";
import SmartDPHighlighted from "app/pages/SmartDP/Search/SmartDPHighlighted/SmartDPHighlighted";
import SmartDPReInsurance from "app/pages/SmartDP/Search/SmartDPReInsurance/SmartDPReInsurance";

const IMG_SIZES = {
	medium: { width: 978 },
	small: { width: 768 },
	large: { width: 1847 },
	xlarge: { width: 1847 },
};

const SmartDPSearch = ({
	destinations,
	departureCities,
	cabins,
	departureDateMin,
	departureDateMax,
	initialValues,
	resetAllSDPProductsFilter,
	marketing,
}) => {
	const history = useHistory();
	const { resolution } = useContext(AppGlobalsContext);

	useEffect(() => {
		sendTagOnSDPSearchPage();
	}, []);

	const handleFormSubmission = useCallback(data => {
		const queryParams = buildSDPSearchPayload(data);

		resetAllSDPProductsFilter();

		history.push({ pathname: "/sdp/listing", search: queryParams });
	}, []);

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const backgroundImageUrl = isMobile ? marketing.heroImageMobile : marketing.heroImageDesktop;
	const backgroundImageThumbnail = isMobile
		? marketing.heroImageMobileThumbnail
		: marketing.heroImageDesktopThumbnail;

	let align = "";
	if (marketing.heroTextAlign === SDP_HERO_TEXT_ALIGN.LEFT) {
		align = "flex-start";
	} else if (marketing.heroTextAlign === SDP_HERO_TEXT_ALIGN.RIGHT) {
		align = "flex-end";
	}

	return (
		<div className="smartdp-search">
			<div className="smartdp-search__hero">
				<BackgroundProgressiveImg
					sizes={IMG_SIZES}
					resolution={resolution}
					backgroundPosition={"center"}
					thumbnail={backgroundImageThumbnail}
					src={backgroundImageUrl}
				/>
				<div className="smartdp-search__hero-content">
					<div className="smartdp-search__marketing">
						<div
							className="smartdp-search__marketing-container"
							style={{ alignItems: align }}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: marketing.heroText && marketing.heroText[0],
								}}
							/>
							<div
								dangerouslySetInnerHTML={{
									__html: marketing.heroText && marketing.heroText[1],
								}}
							/>
						</div>
					</div>
					{!isMobile && (
						<div className="smartdp-search__form">
							<SmartDPSearchForm
								onSuccess={handleFormSubmission}
								initialValues={initialValues}
								destinations={destinations}
								departureCities={departureCities}
								departureDateMin={departureDateMin}
								departureDateMax={departureDateMax}
								cabins={cabins}
							/>
						</div>
					)}
				</div>
			</div>
			{isMobile && (
				<div className="smartdp-search__form">
					<SmartDPSearchForm
						onSuccess={handleFormSubmission}
						initialValues={initialValues}
						destinations={destinations}
						departureCities={departureCities}
						departureDateMin={departureDateMin}
						departureDateMax={departureDateMax}
						cabins={cabins}
					/>
				</div>
			)}
			{marketing.homeBlocks?.map((block, index) => {
				if (block._type === SDP_SEARCH_MARKETING_BLOCKS.REASONS_TO_BOOK) {
					return (
						<React.Fragment key={index}>
							<div className="smartdp-search__home-block">
								<SmartDPReasons reasonsToBook={block.items} />
							</div>

							{block.highlightedReasonPicture && (
								<div className="smartdp-search__home-block">
									<SmartDPHighlighted
										resolution={resolution}
										picture={block.highlightedReasonPicture}
										reasonToBook={block.items[0]}
									/>
								</div>
							)}
						</React.Fragment>
					);
				} else if (block._type === SDP_SEARCH_MARKETING_BLOCKS.BANNER) {
					return (
						<div key={index} className="smartdp-search__home-block">
							<SmartDPBanner
								resolution={resolution}
								imageDesktop={block.imageDesktop}
								imageDesktopOverlay={block.imageDesktopOverlay}
								imageMobileOverlay={block.imageMobileOverlay}
								imageDesktopThumbnail={block.imageDesktopThumbnail}
								imageMobile={block.imageMobile}
								imageMobileThumbnail={block.imageMobileThumbnail}
								url={block.link}
								isImmersive={block.immersive}
								openInNewWindow={block.openInNewWindow}
							/>
						</div>
					);
				} else if (block._type === SDP_SEARCH_MARKETING_BLOCKS.REINSURANCE) {
					return (
						<div key={index} className="smartdp-search__home-block">
							<SmartDPReInsurance
								resolution={resolution}
								title={block.title}
								image={block.image}
								thumbnail={block.thumbnail}
								text={block.text}
								openInNewWindow={block.cta?.openInNewWindow}
								buttonLabel={block.cta?.label}
								url={block.cta?.link}
							/>
						</div>
					);
				}

				return false;
			})}
		</div>
	);
};

SmartDPSearch.propTypes = {
	initialValues: PropTypes.object,
	destinations: PropTypes.array,
	departureCities: PropTypes.array,
	cabins: PropTypes.array,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
	resetAllSDPProductsFilter: PropTypes.func,
	marketing: PropTypes.object,
};

SmartDPSearch.defaultProps = {
	initialValues: {},
	destinations: [],
	departureCities: [],
	cabins: [],
	marketing: {},
};

export default React.memo(SmartDPSearch);
