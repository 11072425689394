import React from "react";
import PropTypes from "prop-types";
import "./SmartDPReInsurance.scss";
import Separator from "app/pages/.shared/Separator";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconPictoChevron from "app/pages/.shared/IconPictoChevron";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";

const IMG_SIZES = {
	small: { width: 600 },
	medium: { width: 600 },
	large: { width: 350, height: 260 },
	xlarge: { width: 350, height: 260 },
};

const SmartDPReInsurance = ({
	resolution,
	title = [],
	image,
	thumbnail,
	text = [],
	openInNewWindow,
	buttonLabel,
	url,
}) => {
	const target = openInNewWindow ? "_blank" : "_self";

	return (
		<div className="sdp-reinsurance">
			<div className="sdp-reinsurance__container">
				{title.map((titl, index) => {
					return (
						<span
							key={index}
							className="sdp-reinsurance__title"
							dangerouslySetInnerHTML={{ __html: titl }}
						/>
					);
				})}
				<Separator />
				{text.map((tex, index) => {
					return (
						<div
							key={index}
							className="sdp-reinsurance__texte"
							dangerouslySetInnerHTML={{
								__html: tex,
							}}
						/>
					);
				})}
				<RelativeLink
					to={{ pathname: url }}
					className="sdp-reinsurance__cta"
					design="primary"
					target={target}
					data-cy="reinsurance-cta"
				>
					{buttonLabel}
					<IconPictoChevron />
				</RelativeLink>
			</div>
			<div className="sdp-reinsurance__image">
				<ProgressiveImg
					sizes={IMG_SIZES}
					resolution={resolution}
					src={image}
					thumbnail={thumbnail}
				/>
			</div>
		</div>
	);
};

SmartDPReInsurance.propTypes = {
	resolution: PropTypes.string,
	title: PropTypes.array,
	image: PropTypes.string,
	thumbnail: PropTypes.string,
	text: PropTypes.array,
	openInNewWindow: PropTypes.bool,
	buttonLabel: PropTypes.string,
	url: PropTypes.string,
};

export default React.memo(SmartDPReInsurance);
