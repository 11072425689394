import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import "./DepartureDateSidePanelContent.scss";
import Button from "app/pages/.shared/form/Button";
import CalendarList from "app/pages/.shared/CalendarList/CalendarList";
import { FormattedMessage } from "react-intl";
import IconBack from "app/pages/.shared/static/icons/IconBack";
import { getMonthsYearsInDateInterval } from "app/utils/utils";

const DepartureDateSidePanelContent = ({
	initialDate,
	onDateSelect,
	onClose,
	departureDateMin,
	departureDateMax,
}) => {
	const [selectedDate, setSelectedDate] = useState(initialDate);

	const handleDateSelect = useCallback(date => {
		setSelectedDate(date);
	}, []);

	const handleDateConfirmation = useCallback(() => {
		onDateSelect(selectedDate);
	}, [selectedDate]);

	let months = [];
	if (departureDateMin && departureDateMax) {
		months = getMonthsYearsInDateInterval(departureDateMin, departureDateMax);
	}

	return (
		<div className="departure-date-side-panel-content">
			<header className="departure-date-side-panel-content__header">
				<div onClick={onClose}>
					<IconBack />
				</div>
				<div>
					<FormattedMessage id="sdp.search.departure.date.side.panel.title" />
				</div>
				<div style={{ width: "30px" }} />
			</header>
			<div className="departure-date-side-panel-content__body">
				<CalendarList
					heightOffset={140}
					selectedDate={selectedDate || new Date()}
					months={months}
					onDateSelect={handleDateSelect}
					departureDateMin={departureDateMin}
					departureDateMax={departureDateMax}
				/>
			</div>
			<footer className="departure-date-side-panel-content__footer">
				<Button disabled={!selectedDate} design="primary" onClick={handleDateConfirmation}>
					<FormattedMessage id="sdp.search.departure.date.side.panel.cta" />
				</Button>
			</footer>
		</div>
	);
};

DepartureDateSidePanelContent.propTypes = {
	onDateSelect: PropTypes.func,
	initialDate: PropTypes.object,
	onClose: PropTypes.func,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
};

export default React.memo(DepartureDateSidePanelContent);
