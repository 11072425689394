import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import NumberInputFormik from "app/pages/.shared/NumberInputFormik";
import "./TravellersRoomInput.scss";
import { FieldArray, useFormikContext } from "formik";
import get from "lodash/get";
import usePrevious from "app/utils/usePrevious";
import GlobalErrorMessagesFormik from "app/pages/.shared/form/GlobalErrorMessagesFormik";
import { ALERT_TYPE } from "app/constants";
import DateSelectorInputFormik from "app/pages/.shared/DateSelector/DateSelectorInputFormik";

const CHILDREN_AGE_LIMIT = {
	minAge: 0,
	maxAge: 17,
};

const TravellersRoomInput = ({ name, index }) => {
	const formikContext = useFormikContext();

	// there must be always at least 1 adult per room
	const canDecrement = useCallback(value => {
		return value > 1;
	}, []);

	const occupancy = formikContext.getFieldProps(name).value;
	const meta = formikContext.getFieldMeta(name);

	const previousChildren = usePrevious(occupancy.children);

	useEffect(() => {
		const actualChildrenCount = occupancy.children;
		let childrenBirthdates = occupancy.childrenBirthdates || [];

		if (actualChildrenCount > previousChildren) {
			// attention à ne pas muter childrenBirthdates
			childrenBirthdates = childrenBirthdates.concat([
				{
					day: undefined,
					month: undefined,
					year: undefined,
				},
			]);
		} else if (actualChildrenCount < previousChildren) {
			// attention à ne pas muter childrenBirthdates
			childrenBirthdates = childrenBirthdates.slice(0, childrenBirthdates.length - 1);
		}

		formikContext.setFieldValue(`${name}.childrenBirthdates`, childrenBirthdates);
	}, [occupancy.children]);

	const childrenBirthdates = occupancy.childrenBirthdates || [];

	const birthDateErrors = get(
		formikContext,
		`errors.occupancies[${index}].childrenBirthdates`,
		[]
	);

	const firstError = birthDateErrors.find(birthDateError => birthDateError);

	return (
		<div className="travellers-room-input">
			<div className="travellers-room-input__item">
				<div className="travellers-room-input__label">
					<FormattedMessage id="search.engine.adults.label" />
					<FormattedMessage id="search.engine.adults.age.range.label">
						{msg => <div className="travellers-room-input__label--note">{msg}</div>}
					</FormattedMessage>
				</div>
				<div className="travellers-room-input__input">
					<NumberInputFormik
						id={`${name}.adults`}
						name={`${name}.adults`}
						canDecrement={canDecrement}
					/>
				</div>
			</div>
			<div className="travellers-room-input__item">
				<div className="travellers-room-input__label">
					<FormattedMessage id="search.engine.children.label" />
					<FormattedMessage
						id="search.engine.children.age.range.label"
						values={CHILDREN_AGE_LIMIT}
					>
						{msg => <div className="travellers-room-input__label--note">{msg}</div>}
					</FormattedMessage>
				</div>
				<div className="travellers-room-input__input">
					<NumberInputFormik id={`${name}.children`} name={`${name}.children`} />
				</div>
			</div>

			{birthDateErrors.length > 0 && (
				<GlobalErrorMessagesFormik
					isValid={meta.isValid}
					isSubmitting={meta.isSubmitting}
					message={<FormattedMessage id={firstError.id} />}
					alertType={ALERT_TYPE.ERROR}
				/>
			)}

			<div className="travellers-room-input__birdthdates">
				<FieldArray
					name="occupancies.childrenBirthdates"
					render={() => {
						return childrenBirthdates.map((age, index) => {
							return (
								<div key={index} className="travellers-room-input__birdthdate">
									<div className="travellers-room-input__child-index">
										<FormattedMessage
											id="payment.passengers.child"
											values={{ index: index + 1 }}
										/>
									</div>
									<DateSelectorInputFormik
										isChildrenAgeRequired={true}
										id={`${name}.childrenBirthdates.${index}`}
										name={`${name}.childrenBirthdates.${index}`}
										className="travellers-room-input__children-age"
										data-cy={`${name}-childrenBirthdates-${index}-input`}
										label={
											<FormattedMessage id="search.engine.birthdate.label" />
										}
									/>
								</div>
							);
						});
					}}
				/>
			</div>
		</div>
	);
};

TravellersRoomInput.propTypes = {
	name: PropTypes.string,
	index: PropTypes.number,
};

export default React.memo(TravellersRoomInput);
