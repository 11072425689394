import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SidePanel from "app/pages/.shared/SidePanel/SidePanel";
import { useField, useFormikContext } from "formik";
import DepartureDateSidePanelContent from "app/pages/SmartDP/Search/DepartureDateSidePanelContent/DepartureDateSidePanelContent";
import { FormattedDate } from "react-intl";
import { messagePropType } from "app/utils/propTypes";
import "./DateCalendarInput.scss";
import classNames from "classnames";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import Popover from "react-popover";
import IconLeft from "app/pages/.shared/static/icons/IconLeft";
import { sendTagOnPriceCalendarDateClicked } from "app/utils/analytics";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import Calendar from "react-calendar";
import { isValid, isWithinInterval } from "date-fns";

const DateCalendarInput = props => {
	const [field, meta, helpers] = useField(props);
	const { setFieldValue } = useFormikContext();
	const { shop, resolution } = useContext(AppGlobalsContext);
	const { id, label, className, icon, departureDateMin, departureDateMax } = props;
	const [isSidePanelOpen, toggleSidePanelOpen] = useState(false);
	const { setError } = helpers;

	const handleOnPanelClose = useCallback(() => {
		toggleSidePanelOpen(false);
	}, []);

	const handleOnPanelOpen = useCallback(() => {
		toggleSidePanelOpen(true);
		setError();
	}, []);

	const handleDateConfirmation = useCallback(date => {
		helpers.setValue(date);
		helpers.setTouched(true);
		toggleSidePanelOpen(false);
	}, []);

	const inputClassName = classNames("date-calendar-input", className, {
		"date-calendar-input--touched": (meta.touched && !meta.error) || field.value,
		"date-calendar-input--error": meta.touched && meta.error,
		"date-calendar-input--with-icon": icon,
	});

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const minDate = new Date(departureDateMin);
	const maxDate = new Date(departureDateMax);

	useEffect(() => {
		if (
			isValid(minDate) &&
			isValid(maxDate) &&
			field.value &&
			!isWithinInterval(field.value, {
				start: minDate,
				end: maxDate,
			})
		) {
			setFieldValue("departureDate", "");
		}
	}, [departureDateMin, departureDateMax]);

	return isMobile ? (
		<React.Fragment>
			<div className={inputClassName} onClick={handleOnPanelOpen} data-cy={props["data-cy"]}>
				{icon && <div className="date-calendar-input__icon">{icon}</div>}
				<label htmlFor={id} className="date-calendar-input__label">
					{label}
				</label>
				<div className="date-calendar-input__input">
					{field.value && <FormattedDate value={field.value} />}
				</div>
			</div>
			<SidePanel
				isOpen={isSidePanelOpen && departureDateMin && departureDateMax}
				onClose={handleOnPanelClose}
				direction={"rtl"}
			>
				<DepartureDateSidePanelContent
					onClose={handleOnPanelClose}
					initialDate={field.value}
					onDateSelect={handleDateConfirmation}
					departureDateMin={departureDateMin}
					departureDateMax={departureDateMax}
				/>
			</SidePanel>
		</React.Fragment>
	) : (
		<Popover
			className="date-calendar-input__popover"
			onOuterAction={handleOnPanelClose}
			enterExitTransitionDurationMs={300}
			tipSize={isMobile ? 15 : 9}
			body={
				<div>
					<Calendar
						prevLabel={<IconLeft />}
						nextLabel={<IconRight />}
						className="date-calendar-input__calendar"
						locale={shop}
						onChange={handleDateConfirmation}
						maxDate={maxDate}
						minDate={minDate}
						value={field.value}
						defaultActiveStartDate={field.value || minDate}
						onClickDay={sendTagOnPriceCalendarDateClicked}
						tileClassName="date-calendar-input-tile__tile"
					/>
				</div>
			}
			isOpen={isSidePanelOpen}
			place="below"
		>
			<div className={inputClassName} onClick={handleOnPanelOpen} data-cy={props["data-cy"]}>
				{icon && <div className="date-calendar-input__icon">{icon}</div>}
				<label htmlFor={id} className="date-calendar-input__label">
					{label}
				</label>
				<div className="date-calendar-input__input">
					{field.value && <FormattedDate value={field.value} />}
				</div>
			</div>
		</Popover>
	);
};

DateCalendarInput.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	className: PropTypes.string,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
	icon: PropTypes.element,
	["data-cy"]: PropTypes.string,
};

export default React.memo(DateCalendarInput);
