import SmartDPSearch from "app/pages/SmartDP/Search/SmartDPSearch";
import { connect } from "react-redux";
import get from "lodash/get";
import { resetAllSDPProductsFilter } from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { bindActionCreators } from "redux";
import addHours from "date-fns/addHours";

const mapStateToProps = state => {
	const booking = state.booking;

	const initialValues = {};

	if (get(state, "smartDP.cabins[0]")) {
		initialValues.cabin = get(state, "smartDP.cabins[0]");
	}

	if (booking.cabin.code) {
		initialValues.cabin = booking.cabin;
	}

	if (booking.destinationResort.code) {
		initialValues.destinationResort = booking.destinationResort;
	}
	//
	if (booking.departureCity.code) {
		initialValues.departureCity = booking.departureCity;
	}

	if (booking.departureDate) {
		// @see SmartDPSearch in handleFormSubmission
		// ex Paris : Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
		let departureDate = new Date(booking.departureDate);
		// => Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
		departureDate = addHours(departureDate, departureDate.getTimezoneOffset() / 60);
		// departureDate.geTime() gives 10/6/2021 à 22:00:00
		initialValues.departureDate = departureDate;
	}

	if (booking.occupancies.length > 0) {
		initialValues.occupancies = booking.occupancies;
	}

	if (booking.duration.value) {
		initialValues.duration = {
			code: booking.duration.value,
		};
	}

	return {
		initialValues,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			resetAllSDPProductsFilter,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPSearch);
